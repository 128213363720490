.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-shadow-custom {
  text-shadow: -5px 3px 0 #c9357b, 5px -3px 0 #9ff9fb;
}

.text-shadow-custom-sm {
  text-shadow: -3px 1px 0 #c9357b, 3px -1px 0 #9ff9fb;
}

.text-outline {
  text-shadow: -1px 1px 0 #000, 1px 1px #000, 1px -1px #000, -1px -1px #000;
}

.box-shadow-custom {
  box-shadow: -10px 10px 0 #000;
}

.glitch-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "AnotherDanger";
}

.glitch {
  position: relative;
  font-size: 60px;
  font-weight: bold;
  color: #000;
  letter-spacing: 3px;
  z-index: 1;
}

.glitch:before,
.glitch:after {
  display: block;
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.glitch:before {
  animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  color: #9ff9fb;
  z-index: -1;
}

.glitch:after {
  animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  color: #c9357b;
  z-index: -2;
}

@keyframes glitch-it {
  0% {
     transform: translate(0);
  }
  20% {
     transform: translate(-2px, 2px);
  }
  40% {
     transform: translate(-2px, -2px);
  }
  60% {
     transform: translate(2px, 2px);
  }
  80% {
     transform: translate(2px, -2px);
  }
  to {
     transform: translate(0);
  }
}



/* tailwind mobile */
@media screen and (min-width: 640px) {
  .glitch{
    font-size: 60px;
  }
}

/* tailwind sm */
@media screen and (min-width: 768px) {
  .glitch{
    font-size: 72px;
  }
}

/* tailwind md */
@media screen and (min-width: 1024px) {
  .glitch{
    font-size: 96px;
  }
}

/* tailwind lg */
@media screen and (min-width: 1280px) {
  .glitch{
    font-size: 128px;
  }
}


